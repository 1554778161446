
import './App.scss';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Body from './components/body/Body';
import { Component } from 'react';

class App extends Component {
  render() {
    return (
    <div className="start">
        <Header/>
        <Body/>
        <Footer/>
    </div>);
  } 
}

export default App;
