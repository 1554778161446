
import './body.scss';
import { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import React, { Suspense} from 'react';
import { Helmet } from 'react-helmet'
import ScrollToTop from './../ScrollToTop';


const MainMemo = React.lazy(() => import('./main_page/Main'))
const ClientMemo = React.lazy(() => import('./client_page/Client'))
const ServiceMemo = React.lazy(() => import('./service_page/Service'))
const AboutMemo = React.lazy(() => import('./about_page/About'))
const PromoMemo = React.lazy(() => import('./promo_page/Promo'))
const ContactMemo = React.lazy(() => import('./contact_page/contact'))

class Body extends Component {
  render() {
    return (
    <div className="main_area">
      <Suspense fallback={
             <div class="loader">Loading...
                <Helmet>
                  <title>{ "Loading..." }</title>
                </Helmet>
             </div>
      }>
       <ScrollToTop>
        <Routes>
          <Route path="/" element={<MainMemo />} />
          <Route path="/service" element={<ServiceMemo />} />
          <Route path="/ourclients" element={<ClientMemo />} />
          <Route path="/about" element={<AboutMemo />} />
          <Route path="/promo" element={<PromoMemo />} />
          <Route path="/contact" element={<ContactMemo />} />
        </Routes>
      </ScrollToTop>
      </Suspense>
    </div>);
  } 
}

export default Body;