
import './header.scss';
import { Link } from 'react-router-dom';



import { useState } from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import translationEn from './../../public/lang/translationEn.json';
import translationRu from './../../public/lang/translationRu.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {translation: translationEn},
        ru: {translation: translationRu}
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {escapeValue: false}
})

const Header = (props) => {

    const [menuAnimation, setMenuAnimation ] = useState(false);

    const { t } = useTranslation();

    const languageSwitcher = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    const menuHandler = () => {
        setMenuAnimation(false);
    }

    const menuHandlerButton = () => {
        setMenuAnimation(true);
    }

    return (
        <div className="header_menu">
            <div className="Header">
                <div className="logo_section">
                    <div className="logo_image"></div>
                    {/* <img src={englishImage} alt="language" className="language_changer"/> */}
                </div>
                <ul className="menu_list">
                    <Link className='link_cast' to="/">
                        <li className="menu_item">
                            <p className="text">{t('Header.link_label_home')}</p>
                            <hr/>
                        </li>
                    </Link>
                    <Link className='link_cast' to="service">
                        <li className="menu_item">
                            <p className="text">{t('Header.link_label_service')}</p>
                            <hr/>
                        </li>
                    </Link>
                    <Link className='link_cast' to="ourclients">
                        <li className="menu_item">
                            <p className="text">{t('Header.link_label_clients')}</p>
                            <hr/>
                        </li>
                    </Link>
                    <Link className='link_cast' to="about">
                        <li className="menu_item">
                            <p className="text">{t('Header.link_label_about')}</p>
                            <hr/>
                        </li>
                    </Link>
                    <Link className='link_cast' to="promo">
                        <li className="menu_item">
                            <p className="text">{t('Header.link_label_promo')}</p>
                            <hr/>
                        </li>
                    </Link>
                </ul>
                <div className="contact_lan_section">
                    <select name="langauge" onChange={languageSwitcher}>
                        <option value="en">English</option>
                        <option value="en">Coming Soon</option>
                        {/* <option value="lv">Latvian</option> */}
                    </select>
                    <Link className='link_cast contact_button' to="./contact">
                        <p className="text">{t('Header.link_label_contact')}</p>
                    </Link>
                </div>
            </div>
            <div className="Header_mobile_top_bar">
                <div className={menuAnimation ? 'button_open_menu close' : 'button_open_menu'} onClick={menuHandlerButton}>
                    <hr />
                    <hr className='middle'/>
                    <hr />
                </div>
            </div>
            <div className="wrapper" onClick={menuHandler}>
                <div className={menuAnimation ? 'Header_mobile_side' : 'Header_mobile_side disactive'}>
                    <ul className="menu_list">
                        <Link className='menu_link' to="/">
                            <p className="text">{t('Header.link_label_home')}</p>
                        </Link>
                        <Link className='menu_link' to="service">
                            <p className="text">{t('Header.link_label_service')}</p>
                        </Link>
                        <Link className='menu_link' to="ourclients">
                            <p className="text">{t('Header.link_label_clients')}</p>
                        </Link>
                        <Link className='menu_link' to="about">
                            <p className="text">{t('Header.link_label_about')}</p>
                        </Link>
                        <Link className='menu_link' to="promo">
                            <p className="text">{t('Header.link_label_promo')}</p>
                        </Link>

                        <Link className='menu_link' to="/contact">
                            <p className="text">{t('Header.link_label_contact')}</p>
                        </Link>
                        <select name="langauge" onChange={languageSwitcher}>
                            <option value="en">English</option>
                            <option value="en">Coming Soon</option>
                            {/* <option value="lv">Latvian</option> */}
                        </select>
                    </ul>
                </div>
            </div>
        </div>
        )
    
}

export default Header;